/**
 * @typedef {'admin'|'storefront'} AnalyticsSource
 */

/**
 * @typedef {object} TrackingParams
 * @property {string} eventName Event name
 * @property {string} eventCategory Name of events group (ex 'checkout')
 * @property {number|string|boolean} value The actual value for the event
 * @property {any} [details] Any valid JSON we want in a free form
 */

/**
 * @typedef {object} AnalyticsLib
 * @property {() => void} sendPixelData
 * @property {(params: TrackingParams) => void} track
 */

/**
 * Creates analytics library with clojured source
 * @param {AnalyticsSource} source
 * @returns {AnalyticsLib}
 */
export const analyticsFactory = source => ({
  sendPixelData: () => {
    const impersonated = window.gon?.impersonated
    const environment = window.gon?.environment

    if (impersonated) return
    if (!window.navigator?.sendBeacon) return

    const pixelData = {
      gid: window.gon?.analytics?.ga_id || null,
      current_pathname: window.location.pathname,
      current_query: window.location.search,
      current_host: window.location.host,
      window_height: window.innerHeight,
      window_width: window.innerWidth,
      real_referrer: document.referrer,
      current_store: window.gon?.pixel_store,
      current_user: window.gon?.pixel_user,
      current_email: window.gon?.pixel_email,
      session: window.gon?.pixel_session,
    }

    let endpoint = ''

    if (environment === 'production') {
      endpoint = 'https://pxl.uscreencdn.com/view'
    } else {
      endpoint = 'https://u-bumblebee-staging.herokuapp.com/view'
    }

    (window.pix = window.pix || []).push(pixelData)
    window.navigator.sendBeacon(endpoint, JSON.stringify(window.pix))
  },

  track: params => {
    const impersonated = window.gon?.impersonated
    const environment = window.gon?.environment

    if (impersonated) return
    if (!window.navigator?.sendBeacon) return

    const eventPayload = {
      name: params.eventName || params.event,
      category: params.eventCategory,
      value: params.value,
      details: params.details || {},
      store_id: window.gon?.analytics?.store_id,
      user_id: window.gon?.analytics?.user_id || null,
      ga_id: window.gon?.analytics?.ga_id || null,
      request_referer: window.gon?.analytics?.request_referer || null,
      session: window.gon?.analytics?.pixel_session || null,
      page_location: window.location.href,
      is_publisher: source === 'admin',
      source,
    }

    const endpoint = (environment === 'production') ? 'https://pxl.uscreencdn.com/event'
      : 'https://u-bumblebee-staging.herokuapp.com/event'
    window.navigator.sendBeacon(endpoint, JSON.stringify(eventPayload))
  }
})
